<template>
  <div>
    <loading :enable="loading" />
    <b-row class="card mb-5">
      <div class="w-100 p-3">
        <b-table
            responsive
            class="mt-3"
            :items="items"
            :fields="fields"
            striped
            bordered
            hover
            show-empty
            :sort-by="`total_errors`"
            :sort-desc="true"
        >
          <template v-slot:empty>
            <h4 class="text-center">Nenhum Resultado encontrado</h4>
          </template>

          <template v-slot:cell(actions)="data">
            <div class="d-flex align-items-center justify-content-center">
              <a href="#" @click.prevent="showDetails(data.item.subsee_id)">Ver detalhes</a>
            </div>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </b-row>
  </div>
</template>
<script>

import Loading from "@/components/shared/loading.vue";
import LogsService from "@/services/logs/LogsService";
import logsService from "@/services/logs/LogsService";
import moment from "moment";

export default {
  components: {Loading},
  data() {
    return {
      loading: false,
      items: [],
      lots: [],
      clientSearch: '',
      filter: {
        lot: null,
      },
      fields: LogsService.getFieldsTableClientErrors(),
    }
  },
  async mounted() {
    await this.getAll();
  },
  methods: {
    moment() {
      return moment
    },
    async getAll() {
      this.loading = true;
      await logsService.getClientsWithErrors().then((response) => {
        this.items = response.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    showDetails(client) {
      this.$router.push(`/client-details/${client}`);
    }
  }
}
</script>